<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-md-block d-lg-none">
    <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
       href="javascript:void(0)">
      <i class="mdi mdi-menu font-24 text-white"></i>
    </a>
  </li>
  <!--<li class="">-->
  <!--<div class="m-t-15">-->
  <!--<a href="https://www.wrappixel.com/templates/monster-angular-dashboard/" class="btn btn-block btn-success text-white ml-3">modern sky 唱片总览</a>-->
  <!--</div>-->
  <!--</li>-->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!--<li class="nav-item pr-2 d-none d-md-block">-->
    <!--<form class="app-search">-->
      <!--<input type="text" class="form-control" placeholder="Search for...">-->
      <!--<a class="srh-btn"><i class="ti-search"></i></a>-->
    <!--</form>-->
  <!--</li>-->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <!--<li class="nav-item dropdown" ngbDropdown placement="bottom-right">-->
    <!--<a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"-->
       <!--href="javascript:void(0)"-->
       <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
      <!--<img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">-->
    <!--</a>-->
    <!--<div class="dropdown-menu user-dd" ngbDropdownMenu>-->
      <!--<a class="dropdown-item" href="javascript:void(0)">-->
        <!--<i class="ti-user m-r-5 m-l-5"></i> My Profile</a>-->
      <!--<a class="dropdown-item" href="javascript:void(0)">-->
        <!--<i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>-->
      <!--<a class="dropdown-item" href="javascript:void(0)">-->
        <!--<i class="ti-email m-r-5 m-l-5"></i> Inbox</a>-->
      <!--<div class="dropdown-divider"></div>-->
      <!--<a class="dropdown-item" href="javascript:void(0)">-->
        <!--<i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>-->
      <!--<div class="dropdown-divider"></div>-->
      <!--<a class="dropdown-item" href="javascript:void(0)">-->
        <!--<i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>-->
      <!--<div class="dropdown-divider"></div>-->
      <!--<div class="p-l-30 p-10">-->
        <!--<a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>-->
      <!--</div>-->
    <!--</div>-->
  <!--</li>-->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
