import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/component/records',
    title: '唱片',
    icon: 'mdi mdi-arrange-bring-forward',
    class: '',
    extralink: false,
    submenu: []
  }
  // ,
  // {
  //   path: '/component/recordDetail',
  //   title: '唱片详情',
  //   icon: 'mdi mdi-arrange-bring-forward',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/dashboard',
  //   title: 'Dashboard',
  //   icon: 'mdi mdi-file',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'UI Components',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  // {
  //   path: '/component/accordion',
  //   title: 'Accordion',
  //   icon: 'mdi mdi-equal',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/alert',
  //   title: 'Alert',
  //   icon: 'mdi mdi-message-bulleted',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/carousel',
  //   title: 'Carousel',
  //   icon: 'mdi mdi-view-carousel',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/dropdown',
  //   title: 'Dropdown',
  //   icon: 'mdi mdi-arrange-bring-to-front',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/modal',
  //   title: 'Modal',
  //   icon: 'mdi mdi-tablet',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/pagination',
  //   title: 'Pagination',
  //   icon: 'mdi mdi-backburger',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/poptool',
  //   title: 'Popover & Tooltip',
  //   icon: 'mdi mdi-image-filter-vintage',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/progressbar',
  //   title: 'Progressbar',
  //   icon: 'mdi mdi-poll',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/rating',
  //   title: 'Ratings',
  //   icon: 'mdi mdi-bandcamp',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/tabs',
  //   title: 'Tabs',
  //   icon: 'mdi mdi-sort-variant',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/timepicker',
  //   title: 'Timepicker',
  //   icon: 'mdi mdi-calendar-clock',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/buttons',
  //   title: 'Button',
  //   icon: 'mdi mdi-blur-radial',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/card',
  //   title: 'Card',
  //   icon: 'mdi mdi-arrange-bring-forward',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/toast',
  //   title: 'Toast',
  //   icon: 'mdi mdi-alert',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // }
];
